export const dataStatus = {
    FR:
        [
            'Vous dit bonjour!',
            'A hâte de vous rencontrer. ',
            'A codé ce site avec ❤ ...',
            'Et avec React.',
            'Sait se former tout seul.',
            'Est aussi un mari...',
            'Et un papa.',
            'Est un grand gaillard de 2m ...',
            'Qui aime le rugby ...',
            'Les jeux de société ...',
            'mais n\'a pas de chance aux dés.',
            'fait des blagues à papa  ...',
            'et n\'a plus rien à dire.'
        ],
    EN:
        [
            'Says hello !',
            'Is looking to meet you. ',
            'Has coded with ❤ ...',
            'And using React.',
            'Is a selfmade man.',
            'Is also a husband...',
            'And a dad.',
            'Is a 6.5ft big boy ...',
            'Loves rugby ...',
            'Board games ...',
            'But is very unlucky.',
            'Makes some dad jokes  ...',
            'And has nothing more to say.'
        ],

}
